export default{

    header:{
        'espanol':{
            idiomas:'Idiomas',
            servicios:{
                name:'Servicios',
                content:{
                    desarrollo:'Desarrollo Web',
                    seo:'SEO',
                    appMoviles:'Aplicaciones Móviles',
                    tiendas:'Tiendas Online',
                    redes:'Redes Sociales',
                    marketing:'Marketing Digital'
                }
            },
            nosotros:'Nosotros',
            testimonios:'Testimonios',
            marcas:'Marcas',
            clientes:'Clientes',
            portafolio:'Portafolio',
            contacto:'Contacto'
        },
        'english':{
            idiomas:'Lenguages',
            servicios:{
                name:'Services',
                content:{
                    desarrollo:'Desarrollo Web',
                    seo:'SEO',
                    appMoviles:'Aplicaciones Móviles',
                    tiendas:'Tiendas Online',
                    redes:'Redes Sociales',
                    marketing:'Marketing Digital'
                }
            },
            nosotros:'About Us',
            testimonios:'Testimonios',
            marcas:'Marcas',
            clientes:'Clientes',
            portafolio:'Portafolio',
            contacto:'Contact'
        }
    }
}
