export default{
    banner:{
        'espanol':{
            title:{
                first:'Cumple tus Objetivos',
                second:'Comerciales con Nosotros'
            },
            subtitle:{
                first:'En un mundo competitivo, los grandes solo eligen a los mejores',
                second:'JLGV',
                button:'Nuestro Portafolio'
            }
        },
        'english':{
            title:{
                first:'Cumple tus Objetivos',
                second:'Comerciales con Nosotros'
            },
            subtitle:{
                first:'En un mundo competitivo, los grandes solo eligen a los mejores',
                second:'JLGV',
                button:'Nuestro Portafolio'
            }
        }
    },
    ourServices:{
        'espanol':{
            title:'Nuestros Servicios',
            services:{
                web:{
                    title:`Desarrollo Web`,
                    content:`Desarrollamos sitios web con la mejor tecnología haciéndolos más amigables, visualmente
                    atractivos, funcionales e intuitivos en la navegación.`
                },
                seo:{
                    title:`SEO`,
                    content:`Colocamos tu sitio web en primer lugar en los principales motores de busqueda (Google, Bing, Yahoo).`
                },
                app:{
                    title:`Desarrollo de Aplicaciones Móviles`,
                    content:`Diseñamos la aplicación móvil para tu empresa de acuerdo a tus necesidades e imagen. Causando así una mejor impresión e interactuando con tus clientes.`
                },
                stores:{
                    title:`Tiendas Online`,
                    content:`Una  tienda  sin  presencia  física  de  una  sucursal,  sin  los  gastos  fijos  que  ésta  ocasiona  es  posible, aprovechando  las  ventajas  de  la
                        tecnología  siendo  operadas  directamente  de  tu  oficina  o  las  nuestras.`
                },
                socials:{
                    title:`Redes Sociales`,
                    content:`Manejo de redes sociales de tu empresa mediante el marketing específico con la finalidad de que llegues  a  tu  público  objetivo.  Todo  esto  con
                    la  finalidad  de  que  mantengas  tu  presencia  en  el  mercado.`
                },
                marketing:{
                    title:`Marketing Digital`,
                    content:`Construimos tu marca a partir de las emociones,
                    sentimientos y percepciones que ocurren en la mente de tus consumidores.`
                },
            }

        },
        'english':{
            title:'Nuestros Servicios',
            services:{
                web:{
                    title:`Desarrollo Web`,
                    content:`Desarrollamos sitios web con la mejor tecnología haciéndolos más amigables, visualmente
                    atractivos, funcionales e intuitivos en la navegación.`
                },
                seo:{
                    title:`SEO`,
                    content:`Colocamos tu sitio web en primer lugar en los principales motores de busqueda (Google, Bing, Yahoo).`
                },
                app:{
                    title:`Desarrollo de Aplicaciones Móviles`,
                    content:`Diseñamos la aplicación móvil para tu empresa de acuerdo a tus necesidades e imagen. Causando así una mejor impresión e interactuando con tus clientes.`
                },
                stores:{
                    title:`Tiendas Online`,
                    content:`Una  tienda  sin  presencia  física  de  una  sucursal,  sin  los  gastos  fijos  que  ésta  ocasiona  es  posible, aprovechando  las  ventajas  de  la
                        tecnología  siendo  operadas  directamente  de  tu  oficina  o  las  nuestras.`
                },
                socials:{
                    title:`Redes Sociales`,
                    content:`Manejo de redes sociales de tu empresa mediante el marketing específico con la finalidad de que llegues  a  tu  público  objetivo.  Todo  esto  con
                    la  finalidad  de  que  mantengas  tu  presencia  en  el  mercado.`
                },
                marketing:{
                    title:`Marketing Digital`,
                    content:`Construimos tu marca a partir de las emociones,
                    sentimientos y percepciones que ocurren en la mente de tus consumidores.`
                },
            }

        }
    },
    agency:{
        'espanol':{
            title:'Agencia Especializada en Marketing Digital',
            textOne: `Somos una empresa fundada en agosto 2019, debido a las necesidades del mercado de tener una empresa confiable.
                       Una  empresa  conformada  por  diversos  asociados  y  expertos profesionales en los servicios que ofrecemos.`,
            textTwo: `Formamos  un  grupo  de  profesionales  que  conjuga  diversos  servicios  y  productos  necesarios  para  el   crecimiento   de   tu   empresa,   desarrollamos   sitios   web,   manejamos   tus   redes sociales, desarrollamos coaching  empresarial,  diseño  de  imagen  corporativa  y  más.  Hemos  hecho  alianzas con los mejores
                       de cada especialidad para brindar a tu empresa las soluciones que están buscando en un solo lugar.`,
            happyCustomers:`CLIENTES FELICES`,
            doneProyects:`PROYECTOS REALIZADOS`,
            ongoingProyects:`PROYECTOS EN MARCHA`
        },
        'english':{
            title:'Agencia Especializada en Marketing Digital',
            textOne: `Somos una empresa fundada en agosto 2019, debido a las necesidades del mercado de tener una empresa confiable.
                       Una  empresa  conformada  por  diversos  asociados  y  expertos profesionales en los servicios que ofrecemos.`,
            textTwo: `Formamos  un  grupo  de  profesionales  que  conjuga  diversos  servicios  y  productos  necesarios  para  el   crecimiento   de   tu   empresa,   desarrollamos   sitios   web,   manejamos   tus   redes sociales, desarrollamos coaching  empresarial,  diseño  de  imagen  corporativa  y  más.  Hemos  hecho  alianzas con los mejores
                       de cada especialidad para brindar a tu empresa las soluciones que están buscando en un solo lugar.`,
            happyCustomers:`CLIENTES FELICES`,
            doneProyects:`PROYECTOS REALIZADOS`,
            ongoingProyects:`PROYECTOS EN MARCHA`
        }
    },
    portfolio:{
        'espanol':{
            title:'Nuestro Portafolio',
            subtitle:'Parte de nuestros proyectos.',
            button:'Portafolio'
        },
        'english':{
            title:'Nuestro Portafolio',
            subtitle:'Parte de nuestros proyectos.',
            button:'Portfolio'
        }
    },
    customers:{
        'espanol':{
            title:'Testimonios de nuestros clientes',
            customerOne:{
                title:'Excelente Servicio...',
                content:`Excelente servicio y atención de parte de su personal,
                trabajo de calidad y en el tiempo prometido.`,
                customer:'Mauricio Gonzales,',
                job:`Representante/KANGOT`
            },
            customerTwo:{
                title:'Muy satisfecho...',
                content:`Es verdad que la calidad no tiene por que ser cara,
                muy buen servicio. Felicidades y gracias.`,
                customer:'Francisco Trasviña,',
                job:`Dueño/Restaurante "La casa vieja".`
            },
            customerThree:{
                title:'Lo que necesitábamos...',
                content:`Muy buenas opciones de páginas, muy buen servicio en redes sociales al precio justo y
                con resultados comprobables.`,
                customer:'Ing. Jorge A. Patiño,',
                job:'CEO/DEMISA'
            },
        },
        'english':{
            title:'Testimonios de nuestros clientes',
            customerOne:{
                title:'Excelente Servicio...',
                content:`Excelente servicio y atención de parte de su personal,
                trabajo de calidad y en el tiempo prometido.`,
                customer:'Mauricio Gonzales,',
                job:`Representante/KANGOT`
            },
            customerTwo:{
                title:'Muy satisfecho...',
                content:`Es verdad que la calidad no tiene por que ser cara,
                muy buen servicio. Felicidades y gracias.`,
                customer:'Francisco Trasviña,',
                job:`Dueño/Restaurante "La casa vieja".`
            },
            customerThree:{
                title:'Lo que necesitábamos...',
                content:`Muy buenas opciones de páginas, muy buen servicio en redes sociales al precio justo y
                con resultados comprobables.`,
                customer:'Ing. Jorge A. Patiño,',
                job:'CEO/DEMISA'
            },
        }
    },
    carrousel:{
        'espanol':{
            carrouselOne:{
                title:'Conócenos',
                content:`En GAVATER podrás encontrar lo necesario para que tu empresa sea reconocida. Contarás con el apoyo de expertos en cada una de las
                 áreas que manejamos para proporcionarte todo lo necesario en  el  posicionamiento  de  tu  empresa  y  de  esta  manera  mejorar  los
                 estándares  de  calidad  de  tu  negocio. También contarás con el apoyo para que tu personal
                 ofrezca la calidad en el servicio que tu mercado objetivo requiere. En GAVATER nuestra prioridad es tu empresa.`
            },
            carrouselTwo:{
                title:'Estamos en México',
                content:`Nuestras oficinas se encuentran ubicadas en la Ciudad de Ensenada, Baja California, con presencia virtual  a  nivel  nacional e
                internacional,  y  gracias  a  nuestra  red  de  representantes  y  aliados  estratégicos  con  representación en algunos  de  los  estados
                de la República con la visión a futuro de seguir creciendo. Nuestra meta es ofrecerte las mejores soluciones para que
                tú no tengas que preocuparte por lo más valioso que tienes: TU MARCA, TU EMPRESA Y TU GENTE.`
            }
        },
        'english':{
            carrouselOne:{
                title:'Conócenos',
                content:`En GAVATER podrás encontrar lo necesario para que tu empresa sea reconocida. Contarás con el apoyo de expertos en cada una de las
                 áreas que manejamos para proporcionarte todo lo necesario en  el  posicionamiento  de  tu  empresa  y  de  esta  manera  mejorar  los
                 estándares  de  calidad  de  tu  negocio. También contarás con el apoyo para que tu personal
                 ofrezca la calidad en el servicio que tu mercado objetivo requiere. En GAVATER nuestra prioridad es tu empresa.`
            },
            carrouselTwo:{
                title:'Estamos en México',
                content:`Nuestras oficinas se encuentran ubicadas en la Ciudad de Ensenada, Baja California, con presencia virtual  a  nivel  nacional e
                internacional,  y  gracias  a  nuestra  red  de  representantes  y  aliados  estratégicos  con  representación en algunos  de  los  estados
                de la República con la visión a futuro de seguir creciendo. Nuestra meta es ofrecerte las mejores soluciones para que
                tú no tengas que preocuparte por lo más valioso que tienes: TU MARCA, TU EMPRESA Y TU GENTE.`
            }
        }
    },
    ourBrands:{
        'espanol':{
            title:'Nuestras Marcas'
        },
        'english':{
            title:'Our Brands'
        }
    },
    footer:{
        'espanol':{
            services:{
                title:'Servicios',
                web:'Desarrollo Web',
                seo:'SEO',
                app:'Aplicaciones Móviles',
                online:'Tiendas Online',
                socials:'Redes Sociales',
                marketing:'Marketing Digital'
            },
            support:{
                title:'Soporte',
                contact:'Contacto',
                policy:'Política de Privacidad',
                terms:'Términos & Condiciones'
            },
            bottomTitle:'Derechos reservados'
        },
        'english':{
            services:{
                title:'Services',
                web:'Desarrollo Web',
                seo:'SEO',
                app:'Aplicaciones Móviles',
                online:'Tiendas Online',
                socials:'Redes Sociales',
                marketing:'Marketing Digital'
            },
            support:{
                title:'Support',
                contact:'Contacto',
                policy:'Política de Privacidad',
                terms:'Términos & Condiciones'
            },
            bottomTitle:'Derechos reservados'
        }
    }

}
