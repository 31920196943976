/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./vendor/jquery-3.2.1.min.js");
require("./bootstrap");

window.Vue = require("vue");

Vue.component(
    "form-contact",
    require("./components/ContactUsForm.vue").default
);
Vue.component(
    "terminos-condiciones",
    require("./components/TermsConditions.vue").default
);
Vue.component(
    "politicas-privacidad",
    require("./components/PrivacyPolicy.vue").default
);
Vue.component(
    "angeles",
    require("./components/proyects/Angeles.vue").default
);
Vue.component(
    "frenos-unicos",
    require("./components/proyects/FrenosUnicos.vue").default
);
Vue.component(
    "vac-co",
    require("./components/proyects/Vacco.vue").default
);
Vue.component(
    "baja-mri",
    require("./components/proyects/BajaMri.vue").default
);
Vue.component(
    "edser-transportes",
    require("./components/proyects/EdserTransportes.vue").default
);
Vue.component("ecobac", require("./components/proyects/Ecobac.vue").default);
Vue.component(
    "joal-produce",
    require("./components/proyects/JoalProduce.vue").default
);
Vue.component(
    "verde-esperanza",
    require("./components/proyects/VerdeEsperanza.vue").default
);
Vue.component(
    "alonso-tours",
    require("./components/proyects/AlonsoTours.vue").default
);
Vue.component(
    "farmacia-89",
    require("./components/proyects/SuperFarmacia.vue").default
);

Vue.component(
    "california-construction",
    require("./components/proyects/CaliforniaConstruction.vue").default
);

import Vuex, { mapState } from "vuex";
import menus from "./lenguages/menus";
import home from "./lenguages/home";

const store = new Vuex.Store({
    state: {
        lenguage: "espanol",
        optionsLenguage: [
            { text: "Español", value: "espanol" },
            { text: "English", value: "english" }
        ]
    },
    mutations: {
        setLenguage: function(state, lenguage) {
            state.lenguage = lenguage;
        }
    }
});

const app = new Vue({
    el: "#app",
    store: store,
    mounted() {},
    computed: {
        lenguage: {
            get: function() {
                return this.$store.state.lenguage;
            },
            set: function(value) {
                this.$store.commit("setLenguage", value);
            }
        },
        ...mapState(["optionsLenguage"])
    },
    data() {
        return {
            menus,
            home,
            lang:document.documentElement.lang
        };
    },
    methods: {
        onChange(event) {
            const link = event.target.value;
            window.location.href = link;
        }
    },
});

const appFooter = new Vue({
    el: "#footer-app"
});
