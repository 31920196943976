<template>
     <!-- Featured-designs modal -->
    <div class="modal fade featured-project-modal" id="terminos" tabindex="-1" role="dialog"
        aria-labelledby="featured-project-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <svg class="modal-bg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="379px" height="369px">
                    <defs>
                        <linearGradient id="PSgrad_012" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                            <stop offset="0%" stop-color="rgb(237,247,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(237,247,255)" stop-opacity="0" />
                        </linearGradient>

                    </defs>
                    <path fill-rule="evenodd" fill="url(#PSgrad_012)" d="M54.086,281.380 L105.962,327.505 C173.075,387.178 276.496,381.853 336.956,315.610 C397.418,249.367 392.025,147.292 324.911,87.619 L273.035,41.495 C205.921,-18.178 102.501,-12.853 42.040,53.390 C-18.422,119.633 -13.028,221.708 54.086,281.380 Z" />
                </svg>
                <!-- End of .modal-bg -->

                <svg class="featured-project-modal-bg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="379px" height="369px">
                    <defs>
                        <linearGradient id="PSgrad_013" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                            <stop offset="0%" stop-color="rgb(237,247,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(237,247,255)" stop-opacity="0" />
                        </linearGradient>

                    </defs>
                    <path fill-rule="evenodd" fill="url(#PSgrad_013)" d="M54.086,281.380 L105.962,327.505 C173.075,387.178 276.496,381.853 336.956,315.610 C397.418,249.367 392.025,147.292 324.911,87.619 L273.035,41.495 C205.921,-18.178 102.501,-12.853 42.040,53.390 C-18.422,119.633 -13.028,221.708 54.086,281.380 Z" />
                </svg>
                <!-- End of .modal-bg -->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="ml-symtwo-24-multiply-cross-math"></i>
                </button>
                <!-- End of .close -->

                <div class="modal-body">
                    <div class="row align-items-center">
                       <div class="col-lg-12">
                         <div class="text-content">
                           <h3>Términos y Condiciones</h3>

                           <p class="text-justify">
                             Las reglas que a continuación se describen son los términos y condiciones que permiten
                             la regulación del uso del sitio denominado www.gavater.com, mismo que es propiedad intelectual
                              y desarrollo de GAVATER S.A.P.I. de C.V. a quien en lo sucesivo se denominara “LA EMPRESA”
                           </p>
                           <p class="text-justify">
                              Denominaremos “EL CLIENTE” a todas las personas físicas, físicas con actividad empresarial
                              o empresas que tienen acceso a la pagina para conocer, ver, revisar, interactuar, solicitar
                              cotización de proyectos, platicar y tener cualquier tipo de comunicación con la empresa.
                           </p>
                           <p class="text-justify">
                              LA EMPRESA, utilizara la pagina denominada www.gavater.com como medio publicitario para dar
                              a conocer los tipos de servicios que se ofertan en la misma, utilizara cookies con la finalidad
                               de medir y determinar los alcances de las campañas publicitarias que se decidan impulsar, así
                                como las ubicaciones de las personas que accedan a la pagina por ciudad.
                           </p>
                           <p class="text-justify">
                               LA EMPRESA, cuenta con una línea de acceso directo por medio de WhattsApp, misma que servirá de
                               comunicación entre EL CLIENTE y LA EMPRESA, con la finalidad de proveer un medio mas directo de
                               acceso al mismo, la información vertida en dicho medio de comunicación es de uso informativo y queda
                               reservado en los archivos de la empresa para dar seguimiento de promociones o información adicional.
                           </p>
                           <p class="text-justify">
                             LA EMPRESA, cuenta con una línea de solicitud de información donde se colocará la mínima información
                             básica de EL CLIENTE esta información es confidencial se reservará exclusivamente para el uso de datos
                             de LA EMPRESA, pero al mismo tiempo servirá para enviar publicidad de LA EMPRESA y de los directorios
                             publicitarios y servicios que se realizan en la misma.
                           </p>
                           <p class="text-justify">
                             “EL CLIENTE” deberá de proporcionar sus datos reales tales como, nombre, teléfono, correo electrónico,
                             ciudad donde radica, estado, tipo de proyecto de su interés a desarrollar, esto con la finalidad de
                             poder proporcionar información real al momento de cotizar algún proyecto de interés.
                           </p>
                           <p class="text-justify">
                               “EL CLIENTE” acepta completamente los términos de uso y Condiciones de la pagina www.gavater.com y
                               acepta no generar ningún tipo de demanda, queja o querella en contra de la misma o de “LA EMPRESA”.
                           </p>
                           <div class="text-right">
                             <strong>&Uacute;ltima modificación 08 de febrero de 2020.</strong>
                           </div>
                         </div>
                       </div>
                    </div>
                    <!-- End of .row -->
                </div>
                <!-- End of .modal-body -->
            </div>
            <!-- End of .modal-content -->
        </div>
        <!-- End of .modal-dialog -->
    </div>
    <!-- End of .modal -->
</template>
<script>
export default {
    mounted() {

    },
}
</script>
