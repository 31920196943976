<template>
     <!-- Featured-designs modal -->
    <div class="modal fade featured-project-modal" id="privacidad" tabindex="-1" role="dialog"
        aria-labelledby="featured-project-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <svg class="modal-bg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="379px" height="369px">
                    <defs>
                        <linearGradient id="PSgrad_012" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                            <stop offset="0%" stop-color="rgb(237,247,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(237,247,255)" stop-opacity="0" />
                        </linearGradient>

                    </defs>
                    <path fill-rule="evenodd" fill="url(#PSgrad_012)" d="M54.086,281.380 L105.962,327.505 C173.075,387.178 276.496,381.853 336.956,315.610 C397.418,249.367 392.025,147.292 324.911,87.619 L273.035,41.495 C205.921,-18.178 102.501,-12.853 42.040,53.390 C-18.422,119.633 -13.028,221.708 54.086,281.380 Z" />
                </svg>
                <!-- End of .modal-bg -->

                <svg class="featured-project-modal-bg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="379px" height="369px">
                    <defs>
                        <linearGradient id="PSgrad_013" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                            <stop offset="0%" stop-color="rgb(237,247,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(237,247,255)" stop-opacity="0" />
                        </linearGradient>

                    </defs>
                    <path fill-rule="evenodd" fill="url(#PSgrad_013)" d="M54.086,281.380 L105.962,327.505 C173.075,387.178 276.496,381.853 336.956,315.610 C397.418,249.367 392.025,147.292 324.911,87.619 L273.035,41.495 C205.921,-18.178 102.501,-12.853 42.040,53.390 C-18.422,119.633 -13.028,221.708 54.086,281.380 Z" />
                </svg>
                <!-- End of .modal-bg -->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="ml-symtwo-24-multiply-cross-math"></i>
                </button>
                <!-- End of .close -->

                <div class="modal-body">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="text-content">
                                    <h3>Aviso de Privacidad Integral</h3>
                                    <p align="justify">En cumplimiento con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en lo sucesivo “la Ley”), cuyas disposiciones claramente coadyuvan con nuestro objetivo de proteger sus datos personales, Gavater S.A.P.I. de C.V.  (en lo sucesivo “la Empresa”) con domicilio en Av. México No. 425, Col. Aviación, Ensenada Baja California C.P. 22813, México, es el responsable del uso, protección y tratamiento de sus Datos Personales y hace de su conocimiento que los datos proporcionados por usted serán tratados de manera confidencial. </p>

                                    <h4 class="purpura">Finalidades del Tratamiento de sus Datos Personales </h4>
                                    <p align="justify">Los datos personales que recabamos serán utilizados para las siguientes finalidades primarias tanto para ofertar nuestros servicios y productos como para brindar nuestros servicios de soporte: </p>
                                    <ul>
                                        <li>Contacto para oferta de productos y servicios, existentes y/o nuevos. </li>
                                        <li>Entendimiento de su informacion para prospección comercial.</li>
                                        <li>Contacto para oferta de promociones y/o especiales de nuestros clientes.</li>
                                        <li>Registrar la información de los clientes para la facturación de productos y servicios una vez que se convierten en clientes.</li>
                                    </ul>
                                    <p align="justify">De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para ofertar y/o brindar nuestros productos y servicios, pero que nos permiten y facilitan brindarle una mejor atención:</p>
                                    <ul>
                                        <li>Aplicación de encuestas y evaluaciones del servicio otorgado.</li>
                                    </ul>
                                    <p align="justify">Usted tendrá un plazo de 3 días hábiles para que, de ser el caso, manifieste su negativa a “la Empresa” para el uso de sus datos personales conforme a las finalidades secundarias antes descritas y den origen a la relación jurídica entre “la Empresa” y el titular.</p>

                                    <p align="justify">Si no manifiesta dentro de los referidos 3 días hábiles su negativa para el tratamiento de sus datos personales conforme a las finalidades secundarias informadas, se entenderá que ha otorgado su consentimiento para el tratamiento de los mismos. Sin perjuicio de lo anterior, usted podrá en cualquier momento, manifestar su negativa para el tratamiento de sus datos personales conforme a las finalidades secundarias, a través de la solicitud correspondiente, la cual puede solicitar y enviar directamente al correo contacto@tangohambreya.com. </p>
                                    <p align="justify">
                                        La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros
                                    </p>
                                    <h4 class="purpura">¿Qué datos personales utilizaremos para estos fines? </h4>
                                    <p align="justify">
                                        Para las finalidades señaladas en el presente aviso de privacidad integral, “la Empresa” podrá recabar sus datos personales y/o empresariales de distintas formas:
                                    </p>
                                    <ul>
                                        <li>Datos recabados mediante la visita del titular a nuestro sitio de internet www.spabellezaysalud.com o solicita información a nuestros correosa: nombre del contacto; puesto, empresa; dirección institucional; e-mail y teléfono institucionales.</li>
                                        <li>
                                            Datos que son recabados ya sea directamente y de forma personal del mismo Titular: nombre o razón social; nombre del contacto; teléfono; giro comercial; e-mail. Cliente: nombre; número de cuenta bancaria e interbancaria; número de referencia, Registro Federal de Contribuyentes (RFC); Clave Única de Registro de Población (CURP); domicilio de trabajo; información fiscal y financiera; teléfono; e-mail institucional; firma autógrafa; identificación oficial; puesto.
                                        </li>
                                        <li>Datos que recabamos de otras fuentes como directorios y bases de datos personales reguladas por la ley: nombre o razón social; dirección; e-mail y teléfono.</li>
                                    </ul>
                                        <h4 class="purpura">Transferencia de Datos Personales</h4>

                                        <p align="justify">“La Empresa” podrá dar a conocer los datos personales y/o empresariales recabados con autoridades competentes y entidades de gobierno con el fin de cooperar y/o facilitar información para investigaciones o necesidades que así lo ameriten. Asimismo, se podrán llevar a cabo transferencias a empresas y/o sociedades del mismo grupo de “La Empresa”, de acuerdo al numeral tercero del artículo 37 de la LFPDPPP.</p>

                                        <h4 class="purpura">¿Cómo puede acceder, rectificar o cancelar sus datos personales u oponerse a su uso</h4>

                                        <p align="justify">Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>

                                        <p align="justify">Para el ejercicio de cualquiera de los derechos ARCO, ponemos a su disposición la solicitud respectiva la cual puede solicitar junto con el procedimiento para el ejercicio de dichos derechos, enviando un correo electrónico a contacto@gavater.com Esta solicitud debe ser enviada vía correo electrónico a la cuenta contacto@gavater.com de acuerdo a las instrucciones de la solicitud</p>

                                        <p align="justify">Los datos de contacto del área responsable de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</p>

                                        <p align="jus">Área responsable de datos personales: a) Domicilio: Av. México No. 425, Col. Aviación, Ensenada Baja California C.P. 22813, México. b) Correo electrónico: contacto@gavater.com</p>

                                        <h4 class="purpura">Ampliación de plazos</h4>
                                        <p align="justify">Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.</p>
                                        <h4 class="purpura">Restricciones al ejercicio de los derechos ARCO</h4>
                                        <p align="justify">
                                        El ejercicio de los derechos ARCO podrá restringirse por razones de seguridad nacional, disposiciones de orden público, seguridad y salud públicas o para proteger los derechos de terceras personas, en los casos y con los alcances previstos en las leyes aplicables en la materia, o bien mediante resolución de la autoridad competente debidamente fundada y motivada.</p>
                                        <h4 class="purpura">Usted puede revocar su consentimiento para el uso de sus datos personales</h4>


                                        <p align="justify">Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó o la conclusión de su relación con nosotros. </p>
                                        <p align="justify">
                                        Para la revocación de su consentimiento, ponemos a su disposición la solicitud respectiva la cual puede solicitar junto con el procedimiento para este fin, enviando un correo electrónico a contacto@gavater.com la solicitud debe ser enviada vía correo electrónico a la cuenta contacto@gavater.com de acuerdo a las instrucciones de la solicitud.</p>
                                        <p align="justify">Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de parte de “la Empresa”, si usted presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP), podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la información y protección de datos personales, para mayor información visite:</p>
                                        <a href="http://inicio.ifai.org.mx/SitePages/ifai.aspx ">http://inicio.ifai.org.mx/SitePages/ifai.aspx </a>

                                        <h4 class="purpura">Limitación de uso de sus datos personales</h4>
                                        <ul>
                                            <li>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos el siguiente medio: información para su inscripción en el Registro Público para Evitar Publicidad (REPEP), que está a cargo de la Procuraduría Federal del Consumidor (PROFECO), con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones. Para mayor información sobre dicho registro, usted puede consultar el portal de Internet del REPEP de PROFECO ingresando a https://repep.profeco.gob.mx/ , o bien acudir personalmente a PROFECO.
                                            </li>
                                        </ul>
                                        <h4 class="purpura">El uso de tecnologías de rastreo en nuestro portal de internet</h4>

                                        <p align="justify">
                                            Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines:
                                        </p>
                                        <ul>
                                            <li>Análisis de hábitos de uso de nuestro contenido.</li>
                                            <li>Estrategias para mejorar la experiencia del usuario en nuestro sitio web.</li>
                                            <li>Preferencias de hábitos alimenticios </li>
                                            <li>Preferencias de hábitos de diversión</li>
                                            <li>Preferencias de hábitos de descanso</li>
                                        </ul>
                                        <p align="justify">Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>

                                        <ul>
                                            <li>Idioma preferido por el usuario.</li>
                                            <li>Región en la que se encuentra el usuario.</li>
                                            <li>Tipo de navegador del usuario.</li>
                                            <li>Tipo de sistema operativo del usuario.</li>
                                            <li>Páginas web visitadas por un usuario.</li>
                                            <li>Búsquedas realizadas por un usuario.</li>
                                        </ul>

                                        <h4 class="purpura">¿Cómo puede conocer los cambios en este aviso de privacidad integral? </h4>

                                        <p align="justify">El presente aviso de privacidad integral puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio o por otras causas. </p>

                                        <p align="justify">Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad integral, a través de nuestra página de Internet www.spabellezaysalud.com dentro del cual se llevarán a cabo todas las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad integral.</p>
                              <a href="#" class="custom-btn btn-big grad-style-ef close" data-dismiss="modal">Cerrar</a>
                            </div>
                            <!-- End of .text-content -->
                        </div>
                    </div>
                    <!-- End of .row -->
                </div>
                <!-- End of .modal-body -->
            </div>
            <!-- End of .modal-content -->
        </div>
        <!-- End of .modal-dialog -->
    </div>
    <!-- End of .modal -->
</template>
<script>
export default {
    mounted() {

    },
}
</script>
