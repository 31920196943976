<template>
     <!-- Get a quote Modal Starts -->
    <div class="modal fade get-a-quote-modal" id="get-a-quote-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
         <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="ml-symtwo-24-multiply-cross-math"></i>
                </button>
                    <!-- End of .close -->
            </div>
                <!-- End of .modal-header -->
            <div class="modal-body">
              <div class="contact-form-wrapper">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="contact-wrapper contact-page-form-wrapper">
                            <div class="form-wrapper">
                                <h3 v-text="formLenguage[lenguage].title"></h3>
                                <form class="contact-form" @submit.prevent="sendMail" id="contact-form" method="post">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-6">
                                            <input type="text" name="nombre" v-model="form.nombre" :placeholder="formLenguage[lenguage].name">
                                        </div>

                                        <div class="col-md-12 col-lg-6">
                                            <input type="text" name="correo" v-model="form.correo" :placeholder="formLenguage[lenguage].email">
                                        </div>

                                        <div class="col-md-12 col-lg-6">
                                            <input type="text" name="telefono" v-model="form.telefono" :placeholder="formLenguage[lenguage].phone">
                                        </div>

                                        <div class="col-md-12 col-lg-6">
                                            <input type="text" name="website" v-model="form.website" :placeholder="formLenguage[lenguage].site">
                                        </div>

                                        <div class="col-md-12">
                                            <textarea name="mensaje" v-model="form.mensaje" :placeholder="formLenguage[lenguage].message"></textarea>
                                        </div>
                                        <div class="btn-wrapper">
                                            <button type="submit" class="custom-btn btn-big grad-style-ef" v-text="formLenguage[lenguage].button"></button>
                                        </div>
                                    </div>
                                    <!-- End of .row -->
                                </form>
                                <!-- End of .contact-form -->
                            </div>
                            <!-- End of .form-wrapper -->
                        </div>
                        <!-- End of .contact-form -->
                    </div>
                    <!-- End of .col-lg-7 -->

                    <div class="col-lg-6">
                        <div class="contact-info floating-contact-info">
                            <h5 v-text="data[lenguage].title"></h5>

                            <div class="whats-next-wrapper">
                                <p>
                                    <i class="ml-symone-68-arrow-left-right-up-down-increase-decrease"></i>
                                    <span v-text="data[lenguage].listElementOne"></span>
                                </p>
                                <p>
                                    <i class="ml-symone-68-arrow-left-right-up-down-increase-decrease"></i>
                                    <span v-text="data[lenguage].listElementTwo"></span></p>
                                <p>
                                    <i class="ml-symone-68-arrow-left-right-up-down-increase-decrease"></i>
                                    <span v-text="data[lenguage].listElementThree"></span>
                                </p>
                            </div>
                            <!-- End of .whats-next-wrapper -->

                            <p class="address">
                                {{data[lenguage].phoneMsg}}
                                <a href="tel:6469475543">(646) 947 55 43</a>
                            </p>
                            <!-- End of .address -->

                            <p class="address">
                                {{data[lenguage].emailMsg}}
                                <a href="mailto:contacto@gavater.com">contacto@gavater.com</a>
                            </p>
                            <!-- End of .address -->

                            <div class="social-icons-wrapper">
                                <p v-text="data[lenguage].socialsMsg">Síguenos</p>
                                <ul class="social-icons">
                                    <li>
                                        <a href="https://www.facebook.com/Gavater-111482650330024/" target="_blank" rel="noopener">
                                            <i class="fab fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/Gavateroficial/" target="_blank" rel="noopener">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/gavateroficial/" target="_blank" rel="noopener">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                                <!-- End of .social-icons -->
                            </div>
                        </div>
                        <!-- End of .contact-info -->
                    </div>
                            <!-- End of .col-lg-5 -->
                </div>
                <!-- End of .row -->
                    </div>
                    <!-- End of .container -->
                </div>
                <!-- End of .contact-form-wrapper -->
            </div>
            <!-- End of .modal-body -->


          </div>
            <!-- End of .modal-content -->
        </div>
        <!-- End of .modal-dialog -->
    </div>
    <!-- End of .get-a-quote-modal -->
</template>

<script>
import {mapState} from 'vuex';
export default {

    data(){
        return{
            lenguage:document.documentElement.lang,
            form:{
                nombre:'',
                correo:'',
                telefono:'',
                website:'',
                mensaje:''
            },
            formLenguage:{
                'es':{
                    title:`Contáctanos`,
                    name:'Nombre',
                    email:'Correo',
                    phone:'Teléfono',
                    site:'Website',
                    message:'Mensaje',
                    button:'Enviar'
                },
                'en':{
                    title:`Send Us a Message`,
                    name:'Full Name',
                    email:'Email',
                    phone:'Phone',
                    site:'Website',
                    message:'Message',
                    button:'Contact us now'
                }
            },
            data:{
                'es':{
                    title:`¿Que es lo siguiente?`,
                    listElementOne:'Una llamada y un correo electrónico de uno de nuestros representantes.',
                    listElementTwo:'Estimación de tiempo y costo.',
                    listElementThree:'Una reunión en persona.',
                    phoneMsg:'Llámanos',
                    emailMsg:'Envíanos un correo',
                    socialsMsg:'Síguenos'
                },
                'en':{
                    title:`What’s Next?`,
                    listElementOne:'An email and phone call from one of our representatives.',
                    listElementTwo:'A time & cost estimation.',
                    listElementThree:'An in-person meeting.',
                    phoneMsg:'Give us a call ',
                    emailMsg:'Send us an email',
                    socialsMsg:'Follow us on'
                }
            }
        }
    },
    methods:{
        sendMail(){
            let form = $('#contact-form');
            form.closest('div').find('input,textarea').removeAttr('style');
            form.find('.err-msg').remove();
            form.find('.form-success').removeClass('form-success');

            $('.submit-loading-img').css('display', 'block');
            form.closest('div').find('button[type="submit"]').attr('disabled', 'disabled');

            const params = {
                nombre: this.form.nombre,
                correo: this.form.correo,
                telefono: this.form.telefono,
                website: this.form.website,
                mensaje: this.form.mensaje
            }

            axios.post('/send-contact-form',params).then( response =>{
                $('.submit-loading-img').css('display', 'none');
                form.closest('div').find('button[type="submit"]').removeAttr('disabled');
                if( response.data.status){
                    this.form.nombre   = '';
                    this.form.correo   = '';
                    this.form.telefono = '';
                    this.form.website  = '';
                    this.form.mensaje  = '';

                    form.find('textarea:last-child').after('<div class="success-msg">' + response.data.msg + '</div>');
                    form.find('.success-msg').css({
                        'display': 'block'
                    });
                    setTimeout(function () {
                        $('.success-msg').fadeOut('slow');
                    }, 5000);

                }else{
                    if(response.data.field){
                      form.closest('div').find('[name="' + response.data.field + '"]').addClass('form-success');
                      form.closest('div').find('[name="' + response.data.field + '"]').after('<div class="err-msg">*' + response.data.msg + '</div>');
                    }else{
                        form.find('textarea:last-child').after('<div class="err-msg">' + response.data.msg + '</div>');
                        form.find('.err-msg').css({
                        'display': 'block'
                    });
                    }
                }
            }).catch(

            );
        }
    }
}
</script>
