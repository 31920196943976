<template>
   <!-- End of .grid-item -->
    <div class="a1 grid-item col-md-6 col-lg-4">
        <a href="#" class="featured-content-block content-block" data-toggle="modal" data-target="#california-modal">
            <div class="img-container">
                <img src="images/clients/california-construction/california-construction.jpg" alt="California Construction"
                    class="img-fluid">
            </div>
            <!-- End of .img-container -->
            <h5 class="equalHeight">
                <span class="content-block__sub-title">{{category[lenguage]}}</span>
                California Construction
            </h5>
        </a>
        <!-- End of .featured-content-block -->
    </div>
    <!-- End of .grid-item -->
</template>
<script>
export default {
    data() {
        return {
            lenguage:document.documentElement.lang,
            category: {
                "es": "Desarrollo Web",
                "en": "Web development",
            },
            dialog:{
                'es':{
                    title:'Sitio web',
                    content:`Desarrollamos su sitio web, el objetivo es mostar todos sus servicios y atención al cliente.`,
                    button:'Ir a Sitio Web'
                },
                'en':{
                    title:'Website',
                    content:`We developed their website, the objective is to show all their services and customer service.`,
                    button:'Go to the website'
                }
            }
        }
    },
    mounted() {
        $('#footer-app').append(`
            <!-- Featured-designs modal -->
                <div class="modal fade featured-project-modal" id="california-modal" tabindex="-1" role="dialog"
                    aria-labelledby="featured-project-modal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <svg class="modal-bg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="379px" height="369px">
                                <defs>
                                    <linearGradient id="PSgrad_012" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                        <stop offset="0%" stop-color="rgb(237,247,255)" stop-opacity="1" />
                                        <stop offset="100%" stop-color="rgb(237,247,255)" stop-opacity="0" />
                                    </linearGradient>

                                </defs>
                                <path fill-rule="evenodd" fill="url(#PSgrad_012)" d="M54.086,281.380 L105.962,327.505 C173.075,387.178 276.496,381.853 336.956,315.610 C397.418,249.367 392.025,147.292 324.911,87.619 L273.035,41.495 C205.921,-18.178 102.501,-12.853 42.040,53.390 C-18.422,119.633 -13.028,221.708 54.086,281.380 Z" />
                            </svg>
                            <!-- End of .modal-bg -->

                            <svg class="featured-project-modal-bg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="379px" height="369px">
                                <defs>
                                    <linearGradient id="PSgrad_013" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                        <stop offset="0%" stop-color="rgb(237,247,255)" stop-opacity="1" />
                                        <stop offset="100%" stop-color="rgb(237,247,255)" stop-opacity="0" />
                                    </linearGradient>

                                </defs>
                                <path fill-rule="evenodd" fill="url(#PSgrad_013)" d="M54.086,281.380 L105.962,327.505 C173.075,387.178 276.496,381.853 336.956,315.610 C397.418,249.367 392.025,147.292 324.911,87.619 L273.035,41.495 C205.921,-18.178 102.501,-12.853 42.040,53.390 C-18.422,119.633 -13.028,221.708 54.086,281.380 Z" />
                            </svg>
                            <!-- End of .modal-bg -->

                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="ml-symtwo-24-multiply-cross-math"></i>
                            </button>
                            <!-- End of .close -->

                            <div class="modal-body">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div id="featured-project-carousel" class="carousel slide featured-project-carousel"
                                            data-ride="carousel">
                                            <ol class="carousel-indicators">
                                                <li data-target="#featured-project-carousel" data-slide-to="0" class="active"></li>
                                            </ol>
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <img class="d-block w-100 img-fluid" src="images/clients/california-construction/pagina-web.jpg"
                                                        alt="Baja Mri">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End of .featured-project-carousel -->
                                    </div>
                                    <!-- End of .col-lg-6 -->

                                    <div class="col-lg-6">
                                        <div class="text-content">
                                            <h3 style="color:#000">
                                                <span>${this.dialog[this.lenguage].title}</span>California Construction</h3>
                                            <p style="color:#212529">
                                              ${this.dialog[this.lenguage].content}
                                            </p>
                                            <div class="d-flex align-items-center">
                                              <a href="https://caconstructionco.com" target="_blank" class="custom-btn btn-big grad-style-ef" style="margin-top:0px">${this.dialog[this.lenguage].button}</a>
                                            </div>

                                        </div>
                                        <!-- End of .text-content -->
                                    </div>
                                </div>
                                <!-- End of .row -->
                            </div>
                            <!-- End of .modal-body -->
                        </div>
                        <!-- End of .modal-content -->
                    </div>
                    <!-- End of .modal-dialog -->
                </div>
                <!-- End of .modal -->

        `);
    },
}
</script>
